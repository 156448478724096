import { pick } from 'lodash-es'
import { useMemo } from 'react'
import { useControlField, useFormContext } from 'remix-validated-form'
import { aOrganization, useAtomValue } from '~/atoms'
import { slugify } from '~/utils'
import HiddenFieldValues from './HiddenFieldValues'

type HiddenFieldsProps = {
  slugFields?: string[]
}

export default function HiddenFields({ slugFields }: HiddenFieldsProps) {
  const organization = useAtomValue(aOrganization)
  const formContext = useFormContext()
  const [_id] = useControlField<string>('_id')
  const [tid] = useControlField<string>('tid')
  const [created] = useControlField<string>('created')
  const [user] = useControlField('user')

  const formValues = useMemo(
    () => Object.fromEntries(formContext.getValues()),
    [formContext]
  )

  const slug = useMemo(() => {
    if (!slugFields) {
      return null
    }
    return slugify(
      [
        _id.split('-')[0],
        ...Object.keys(formValues)
          .filter((key) => slugFields.includes(key))
          .map((key) => formValues[key]),
      ].join(' ')
    )
  }, [_id, formValues, slugFields])

  return (
    <>
      <HiddenFieldValues
        name="organization"
        value={pick(organization, '_id', 'slug', 'name')}
      />
      <input type="hidden" name="_id" value={_id} />
      <input type="hidden" name="tid" value={tid} />
      <input type="hidden" name="created" value={created} />
      <input type="hidden" name="modified" value={new Date().toISOString()} />
      {user && <HiddenFieldValues name="user" value={user} />}
      {slugFields && <input type="hidden" name="slug" value={slug || ''} />}
    </>
  )
}
